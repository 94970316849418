import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Write a Stack from Scratch | A Helpful Line-by-Line Code Tutorial"
				}
				subHeader={""}
				description={""}
				keywords={[]}
				embeddedVideo={"WOzBSHCuZxw"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/804517b689775e2f687360a2094a1c57/raw/67eb82bf82446bb8bb59f8290535d6e9e3993c06/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
